.navbar-beatmatch {
  background-color: #2f2f2f;
  margin-bottom: 1em;
  padding: 0em;
}

.navbar-brand-beatmatch {
  color: white;
  margin-left: 1em;
}

.navbar-offcanvas-header-title-beatmatch {
  color: black;
}

.navbar-offcanvas-body-form-label-beatmatch {
  color: black
}