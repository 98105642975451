body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 10px;
  background-color: #010101;
  color: white
}

p {
  margin-bottom: 0px;
}

.navigation-col {
  padding: 0em;
}

.dj-row {
  background-color: #2f2f2f;
  margin-bottom: 1em;
}

.browser-row {
  background-color: #2f2f2f;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.meter {
  padding-top: 41.59px
}

.crossfader {
  padding-top: 20px;
}

.waveform {
  width:'100%';
  height: 150px;
}

.headphoneCueButton {
  margin-top: 5px;
  margin-bottom: 5px
}

input[type=range] {
  -webkit-appearance: none;
  background: transparent;
  
  /*position: absolute;*/
  /* margin: 18px 0;
  width: 100%; */
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
}
input[type=range][class=upFader]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  -webkit-transform: rotate(-90deg) translate(-53%, -53px)
}
input[type=range][class=tempoFader]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #000000;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
  -webkit-transform: rotate(-90deg) translate(-70px, -46px)
}

input[type=range][class=crossfader]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #000000;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #4f4f4f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type=range][class=tempoFader]::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #4f4f4f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type=range][class=upFader]::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 50px;
  width: 16px;
  border-radius: 3px;
  background: #5f5f5f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -23px;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #ffffff;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range][class=upFader]::-moz-range-track {
  width: 5px;
  height: 100%;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #000000;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range][class=tempoFader]::-moz-range-track {
  width: 5px;
  height: 100%;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #000000;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type=range][class=upFader]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 36px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range][class=tempoFader]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 36px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #000000;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #000000;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #000000;
}
input[type=range]:focus::-ms-fill-upper {
  background: #000000;
}

input[type='number']::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}